import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { Page, View, Text, WhiteGreyLayout, SEO, Table, Button, analytics } from 'lib'
import theme from '_appSetup/Theme'
import moment from 'moment'

import copy from 'copy-to-clipboard'
import { useUserData, formatUSD } from '../dashboard/_utils'

import { Share } from '@capacitor/share'

import '_appSetup/Global.css'

const ReferralDetails = (props) => {
  const styles = useStyles(props)
  const [copied, setCopied] = useState(false)

  const { UserData } = useUserData()

  const address = `https://app.stablegains.com/signup?ref=${UserData.referralCode}`

  const shareButtonPressed = async () => {
    analytics.track('Referral share button pressed')

    const canShare = await Share.canShare()

    if (canShare.value === true) {
      const shareResult = await Share.share({
        title: '$25 signup bonus',
        text: `I'm making 10%+ interest on my cash with Stablegains. They're also offering a $25 signup bonus right now. Use my link: ${address}`,
        dialogTitle: 'Share your link',
      })

      analytics.track('Referral link shared', { activityType: shareResult?.activityType })
    } else {
      copy(address)
      setCopied(true)
      analytics.track('Copy Referral Link', { referralCode: UserData.referralCode })
    }

  }

  return (
    <>
      <Text variant='h5' style={styles.header}>Invite friends. Earn interest together. </Text>

      <Text variant='h6' gutterBottom>How do I refer someone?</Text>

      <Text gutterBottom style={styles.refCodeLine}>Ask your friend to use your unique signup link to create a Stablegains account:</Text>

      <Button style={styles.copyButton} disabled={copied || !UserData.referralCode} variant='contained' color='secondary' text={copied ? 'Link copied to your clipboard!' : 'Share your link'} onClick={() => shareButtonPressed()}/>
      {copied && <Text style={styles.explainer} text={`Your link is: ${address}. Everyone that signs up using it counts as your referral.`}/>}

      <View style={styles.separator}/>

      <Text variant='h6' gutterBottom>What are the rewards?</Text>

      <Table
        style={styles.infoTable}
        head={['You earn', 'Your friend receives']}
        rows={
          [
            [`0.5% APY on your friends' deposits for a full year!`, '$25'],
          ]
        }/>

      <Text style={styles.explainer}>
        Example: You'll earn $250 if your friend deposits $50,000 for a year (0.5% of $50,000 is $250).
      </Text>
      <Text style={styles.explainer}>
        You can refer multiple people. Learn more about the rewards <a href='https://stablegains.zendesk.com/hc/en-us/articles/4409440197905' target='_blank'>here</a>.
      </Text>

    </>
  )
}

const ReferralPage = (props) => {
  const styles = useStyles(props)

  const LeftComponent = () => {
    return (
      <View style={styles.leftWrapper}>
        <View style={styles.depositInfoWrapper}>
          <ReferralDetails/>
        </View>
      </View>
    )
  }

  const RightComponent = () => {
    const { UserData } = useUserData()

    const newReferrals = UserData.usersReferred || 0

    const referralData = (UserData.interestChanges && Object.values(UserData.interestChanges).filter(t => t.increment)) || []
    const oldReferrals = referralData.length

    const tableData = []

    if (newReferrals > 0) {
      tableData.push([newReferrals, `0.5% APY on deposits from people you refer`, '1 year from each signup'])
    }

    if (oldReferrals > 0) {
      tableData.push([oldReferrals, '+0.25% increase to your APY', '1 year from each signup'])
    }

    if (UserData.bonusPayments) {
      UserData.bonusPayments.forEach(p => {
        tableData.push(['Your one-time signup bonus', formatUSD(p.amount), `Credited on ${moment(p.date).format('DD MMM YYYY')}`])
      })
    }

    return (
      <View style={styles.leftWrapper}>
        <Text gutterBottom style={styles.textHeader}>Your referral rewards</Text>
        <Table head={['Number of referrals', 'Reward amount', 'Reward timeline']} rows={tableData} noDataCaption="You haven't referred anyone yet."/>
      </View>
    )
  }

  return (
    <Page>
      <SEO title='Referrals'/>
      <WhiteGreyLayout
        leftComponent={LeftComponent}
        rightComponent={RightComponent}
      />
    </Page>
  )
}


const useStyles = makeStyles({
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  depositInfoWrapper: {
    paddingBottom: theme.spacing(4),
    alignSelf: 'stretch',
    wordBreak: 'break-word',
  },
  head: {
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  infoTable: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(4),
  },
  explainer: {
    fontSize: 12,
    color: `${theme.palette.primary.main}88`,
  },
  addressBox: {
    border: '1px solid #00000016',
    borderRadius: '4px',
    padding: theme.spacing(1),
    fontSize: 14,
    flexWrap: 'wrap',
  },
  copyButton: {
    marginTop: 1,
    // marginBottom: theme.spacing(1),
    marginLeft: 0,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  refCodeLine: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

export default ReferralPage
